// This file is autogenerated via the `commonjs` Grunt task. You can require() this file in a CommonJS environment.
require('./umd/util.js')
require('./umd/alert.js')
require('./umd/button.js')
require('./umd/carousel.js')
require('./umd/collapse.js')
require('./umd/dropdown.js')
require('./umd/modal.js')
require('./umd/scrollspy.js')
require('./umd/tab.js')
require('./umd/tooltip.js')
require('./umd/popover.js')